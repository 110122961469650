import React from 'react';
import { CtaBanner } from '@stereoagency/ui';
import flush from 'just-flush';
import { ILazyBlocksImage } from '@/types';
import { useCallToActions, useMediaItems } from '@/hooks';
import { parseHtml } from '@/utils';

type Props = {
  data: {
    image?: ILazyBlocksImage
    videoUrl?: string;
    label?: string
    title: string
    content?: string
    callToActions?: number[]
    variant?: 'default' | 'centered'
    brand_variant?: 'neutral' | 'primary' | 'secondary'
    overlay_style?: 'dark' | 'gradient'
    darkbackground?: boolean
  }
};

export default function NvisoCtaBanner({
  data: {
    image, videoUrl, label, title, content, variant = 'default', overlay_style = 'dark', brand_variant = 'neutral', callToActions,
  },
}: Props) {
  const mediaItems = useMediaItems(flush([image?.id]));
  const callToActionObjects = useCallToActions(callToActions ?? [], false);
  const mediaItem = typeof image?.id === 'number' ? mediaItems.get(image.id) : null;

  return (
    <CtaBanner
      label={label}
      title={title}
      content={content ? parseHtml(content) : undefined}
      variant={variant}
      overlayStyle={overlay_style}
      brandVariant={brand_variant === 'neutral' ? undefined : brand_variant}
      image={mediaItem ? {
        src: mediaItem?.mediaItemUrl ?? '',
        width: mediaItem?.mediaDetails?.width ?? 1920,
        height: mediaItem?.mediaDetails?.height ?? 1080,
      } : undefined}
      videoUrl={videoUrl ?? undefined}
      callToActions={callToActionObjects}
    />
  );
}
